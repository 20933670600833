import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

const CLASS_PREDICATES = {
  xs: (w) => w > 0 && w < 600,
  sm: (w) => w >= 600 && w < 980,
  md: (w) => w >= 980 && w < 1280,
  lg: (w) => w >= 1280 && w < 1920,
  xl: (w) => w >= 1920,
  'lt-sm': (w) => w < 600,
  'lt-md': (w) => w < 980,
  'lt-lg': (w) => w < 1280,
  'lt-xl': (w) => w < 1920,
  'gt-xs': (w) => w >= 600,
  'gt-sm': (w) => w >= 980,
  'gt-md': (w) => w >= 1280,
  'gt-lg': (w) => w >= 1920,
};

const CLASS_LIST = Object.keys(CLASS_PREDICATES);

export const ForumWidthContext = React.createContext(980);

export const useForumWidthContext = () => React.useContext(ForumWidthContext);

const getClassListForWidth = (w) =>
  CLASS_LIST.reduce((result, className) => {
    if (CLASS_PREDICATES[className](w)) {
      result.push(className);
    }
    return result;
  }, []);

class ResponsiveListener extends Component {
  constructor(props) {
    super(props);
    this.nodeRef = React.createRef();
    this.state = { width: props.width };
  }

  componentDidMount() {
    this.classList = Array.from(this.nodeRef.current.classList);
    this.resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      const classList = getClassListForWidth(width);
      this.setState({ width });
      if (!isEqual(this.classList, classList)) {
        this.classList = classList;
        this.nodeRef.current.classList.remove(...CLASS_LIST);
        this.nodeRef.current.classList.add(...classList);
      }
    });
    this.resizeObserver.observe(this.nodeRef.current);
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    return (
      <ForumWidthContext.Provider value={this.state.width}>
        <div ref={this.nodeRef} className={classNames(getClassListForWidth(this.state.width))}>
          {this.props.children}
        </div>
      </ForumWidthContext.Provider>
    );
  }
}

ResponsiveListener.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  console.log('host', host);
  return {
    width: host.dimensions.width || 980,
  };
};

export default connect(mapRuntimeToProps)(ResponsiveListener);
